import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Box, Title, Text } from "../Core";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const WorkBox = styled(Box)``;
const TextBox = styled(Box)`
  font-size: small;
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  left: 20px;
  right: 20px;
  opacity: 0;
  border-radius: 8px;
  z-index: 1;
  padding: .25rem .25rem;
  transition: 0.4s;
  &::before {
    position: absolute;
    content: "";
    background: ${({ theme }) => theme.colors.bg};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.9;
  }
  ${WorkBox}:hover & {
    bottom: 20px;
    opacity: 1;
  }

  h4 {
    font-size: smaller;
  }
`;

const WorkCard = ({ workItem, link, campaign, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  return(<WorkBox className="position-relative" {...rest}>
    <Link to={workItem.attributes.path || "#"} className="d-block">
      { isOpen && workItem.attributes.mime === 'image/jpeg' && <Lightbox mainSrc={
          "http://localhost:1337" + workItem.attributes.url
      }

      enableZoom={false}
      onClick={
        () => {
            setIsOpen(true)
        }
        
    }
    onCloseRequest={
      () => setIsOpen(false)
  }/> }
      
      { (workItem.attributes.mime === 'image/jpeg') ? (<img src={"http://localhost:1337" + workItem.attributes.formats.large.url} alt="" className="w-100"                                                enableZoom={false}
                                         onClick={
                                          () => {
                                              setIsOpen(true)
                                          }
                                      }                 />) : false}
      { (workItem.attributes.mime === 'video/mp4') ? (<video className="w-100" autoPlay muted loop onClick={
                                                () => {
                                                    setIsOpen(true)
                                                }
                                            }                                            enableZoom={false}
                                          ><source src={"http://localhost:1337" + workItem.attributes.url}></source></video>) : false}
    </Link>

    <TextBox>
      <Text variant="tag" mb={2}>
        {workItem.caption}
      </Text>
      <Title variant="card">
        <Link to={(campaign.data[0]) ? `/campaign/${campaign.data[0]?.id}` : "#"}>{workItem.attributes.alternativeText} </Link>
      </Title>
    </TextBox>
  </WorkBox>)
};

export default WorkCard;
